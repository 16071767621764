import React, {useState} from "react";
import OverlayImg from "../../resources/images/home_page/dark-overlay.png";
import {Link} from "gatsby";
import styled from "styled-components";
import theme from "../../styles/theme";
import Img from "gatsby-image";
import fonts from "../../styles/fonts";
import { getProjectLink } from "../../services/links"

const Card = styled.div`
  padding: 5px;
`;

const ProjectImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 234px;
  box-sizing: border-box;
  overflow: hidden;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 605px;
  }
`;

const ProjectImage = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: ${props => props.isVisible ? 'scale(1.15)' : 'none'};
  transition: all 0.3s ease-in-out;
`;

const ClientName = styled(Link)`
  ${fonts.CircularMedium};
  letter-spacing: 0;
  color: ${theme.colors.white};
  position: relative;
  z-index: 11;
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 10px;
  padding-right: 25px;
  text-decoration: none;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 32px;
    line-height: 32px;
    padding-bottom: 0px;
    padding-right: 50px;
  }
`;

const ProjectDescription = styled(Link)`
  ${fonts.MaisonNeueLight};
  color: ${theme.colors.white};
  position: relative;
  z-index: 11;
  font-size: 16px;
  line-height: 20px;
  padding-right: 25px;
  text-decoration: none;
  display: block;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
    line-height: 18px;
    margin-top: 8px;
    padding-right: 50px;
  }
`;

const BottomOverlay = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 221px;
  z-index: 1;
  transition: .5s;
  @media(min-width: ${theme.breakpoints.medium}) {
    opacity: ${props => props.isVisible ? '1' : '0'};
  }

`;

const DivStyled = styled.div`
  position: absolute; 
  left: 25px;
  bottom: 30px;
  transition: .5s;
  @media(min-width: ${theme.breakpoints.medium}) {
    left: 50px; 
    bottom: 55px;
    display: ${props => props.isVisible ? 'block' : 'none'};
  }
`;

const Overlay = styled.div`
  background: rgba(0,0,0,0.35);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  transition: .5s;
  @media(min-width: ${theme.breakpoints.medium}) {
    opacity: ${props => props.isVisible ? '1' : '0'};
  }

`;

const StyledLink = styled(Link)`
  z-index: 9999;
  height: 100%;
  width: 100%;
`;

const ProjectCard = ({imagesRow, index}) => {
    const [visibleTitle, setTitleVisible] = useState(null);
    let project = imagesRow

    if (imagesRow.node) {
      project = imagesRow.node
    }

    return (
        <div onMouseEnter={() => setTitleVisible(index)} onMouseLeave={() => setTitleVisible(null)}>
            <Card className={"cursor-white"}>
                <ProjectImageContainer>
                    <StyledLink to={getProjectLink(project.slug)}>
                        <Overlay isVisible={visibleTitle === index}/>
                    </StyledLink>
                    <ProjectImage fluid={project.thumbnail.fluid} isVisible={visibleTitle === index}/>
                        <DivStyled isVisible={visibleTitle === index} className={"cursor-dot-white"}>
                            <ClientName to={getProjectLink(project.slug)}>{project.clientName}</ClientName>
                            <ProjectDescription to={getProjectLink(project.slug)}>{project.title}</ProjectDescription>
                        </DivStyled>
                    <StyledLink to={getProjectLink(project.slug)}>
                        <BottomOverlay src={OverlayImg} isVisible={visibleTitle === index}/>
                    </StyledLink>
                </ProjectImageContainer>
            </Card>
        </div>
    )
};

export default ProjectCard
