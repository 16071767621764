import React, { Component } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Projects from "../components/projects/Projects";
import get from "lodash/get";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import theme from "../styles/theme";

const LayoutStyled = styled(Layout)`
  margin: 0;
`;

const WhiteBackground = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  @media (min-width: ${theme.breakpoints.large}) {
    padding-left: 136px;
    padding-right: 136px;
  }
`;

class ProjectsPage extends Component {
  render() {
    const path = this.props.location.pathname;
    const industries = get(this, "props.data.allContentfulIndustry.edges");
    const specialisms = get(this, "props.data.allContentfulSpecialism.edges");
    const projectsPage = get(this,"props.data.allContentfulProjectPage.edges")[0].node;

    const { projects } = projectsPage

    return (
      <LayoutStyled colorScheme="dark">
        <SEO
          title="Projects | Society Studio"
          description={documentToPlainTextString(
            projectsPage.contentBlockContent.json
          )}
          fullTitle={true}
          path={path}
        />
        <WhiteBackground>
          <Projects
            intro={documentToReactComponents(projectsPage.intro.json)}
            contentBlockTitle={projectsPage.contentBlockTitle}
            contentBlockContent={documentToReactComponents(
              projectsPage.contentBlockContent.json
            )}
            projects={projects}
            industries={industries}
            specialisms={specialisms}
          />
        </WhiteBackground>
      </LayoutStyled>
    );
  }
}

export default ProjectsPage;

export const pageQuery = graphql`
  query ProjectsQuery {
    allContentfulProjectPage(limit: 1) {
      edges {
        node {
          intro {
            json
          }
          contentBlockTitle
          contentBlockContent {
            json
          }
          projects {
            clientName
            title
            slug
            specialism {
              name
              slug
            }
            industry {
              slug
            }
            thumbnail: thumbnail {
              fluid(maxWidth: 1000, quality: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
    allContentfulIndustry {
      edges {
        node {
          slug
          name
        }
      }
    }
    allContentfulSpecialism {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`;
