import React, {Component} from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import ProjectsList from '../core/ProjectsList';
import {Row, Col, Container} from 'styled-bootstrap-grid';
import PlusDark from '../../resources/images/profile_page/plus-dark.svg';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";

const ProjectsContainer = styled.div`
  padding-top: 120px;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-top: 240px;
  }
`;

const ProjectsDescription = styled.div`
  ${fonts.CircularMedium};
  font-size: 24px;
  line-height: 36px;
  padding-bottom: 25px;
  color: ${theme.colors.black};
  letter-spacing: 0;
  max-width: 750px;
  padding-left: 15px;
  padding-right: 15px;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

const RowStyled = styled(Row)`
  position: relative;
  padding-bottom: ${props => props.bottom ? '50px' : '40px'};
  padding-bottom: ${props => props.filter && '0px'};
  padding-top: ${props => props.bottom && '60px'};
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-bottom: ${props => props.filter ? '0px' : '90px'};
    width: ${props => props.filter && '100%'};
    padding-top: ${props => props.bottom && '105px'};
  }
`;

const ColStyled = styled(Col)`
  flex-direction: ${props => props.filter ? 'column' : 'row'};
  align-items: ${props => props.filter ? 'flex-start' : 'flex-end'};
  justify-content: flex-start;
  padding-left:${props => props.filter && '10px'};
  padding-right:${props => props.filter && '10px'};
  box-sizing: border-box;
  display: ${props => props.desktop ? 'none' : 'flex'};
  @media(min-width: ${theme.breakpoints.medium}) {
    display: ${props => props.mobile && 'none'};
    display: ${props => props.desktop && 'flex'};
    padding: 0;
  }
`;

const FilterButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
  }
  ${fonts.MaisonNeueDemi};
  font-size: 18px;
  line-height: 27px;
  color: ${theme.colors.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11112;
  padding: 0 15px 0 0;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-left: 30px;
  }
`;

const Plus = styled.img`
  height: 12px;
  width: 12px;
  padding: 15px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: ${props => props.hover && 'rotate(270deg)'};
  transform: ${props => props.hover && 'rotate(315deg)'};
  -webkit-transform: ${props => props.open && 'rotate(270deg)'};
  transform: ${props => props.open && 'rotate(315deg)'};
`;

const BigTitle = styled.div`
  ${fonts.CircularMedium};
  letter-spacing: 0;
  color: ${theme.colors.black};
  font-size: 35px;
  line-height: 42px;
  padding-bottom: 25px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 400px;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 62px;
    line-height: 76px;
    padding-bottom: 0;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    padding-left: 30px;
    padding-right: 0;
  }
`;

const DescriptionBottom = styled.div`
  ${fonts.MaisonNeueLight};
  font-size: 16px;
  line-height: 20px;
  color: ${theme.colors.black};
  padding-right: 15px;
  padding-left: 15px;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
    line-height: 28px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    padding-right: 30px;
    padding-left: 0;
  }
`;

const Category = styled.div`
  ${fonts.MaisonNeueDemi};
  font-size: 20px;
  line-height: 33px;
  color: ${theme.colors.white};
  padding-bottom: 20px;
`;

const SubCategory = styled.div`
  font-size: 18px;
  line-height: 33px;
  ${props => props.selectedCategory ? fonts.MaisonNeueDemi : fonts.MaisonNeueLight};
  color: ${props => props.selectedCategory ? theme.colors.white : theme.colors.darkGrey};
  &:hover,
  &:active,
  &:focus {
    color: ${theme.colors.white};
  }
`;

const ButtonText = styled.div`
  border-bottom: 2px solid transparent;
  border-bottom: ${props => props.hover && `2px solid ${theme.colors.black}`};
  border-bottom: ${props => props.open && `2px solid ${theme.colors.black}`};
`;

const FilterContent = styled.div`
  width: 100%;
  padding: 15px;
  opacity: ${props => props.open ? 1 : 0};
  transition: .5s;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding: 70px;
  }
`;

const FilterContainer = styled.div`
  width: 100vw;
  top: 5px;
  position: absolute;
  max-width: 1590px;
  left: -25px;
  margin: auto;
  right: 0;
  min-height: ${props => props.open ? '290px' : 'unset'};
  height: ${props => props.open ? 'auto' : '0'};
  background: ${theme.colors.black};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
  box-sizing: border-box;
  transition: .2s;
  @media(min-width: 593px){
    width: 100%;
    left: 0;
  }
  @media(min-width: ${theme.breakpoints.medium}) {
    min-height: unset;
    width: 100%;
  }
  @media(min-width: 1871px){
    left: -10px;
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

class Projects extends Component {
  state = {
    filterOptionsDisplayed: false,
    filterButtonHovered: false,
    activeFilter: null
  };

  displayFilterOptions = () => this.setState({
    filterOptionsDisplayed: !this.state.filterOptionsDisplayed
  });

  hoverOverFilter = () => this.setState({
    filterButtonHovered: !this.state.filterButtonHovered
  });

  changeFilter = (filter) => {
    this.setState({activeFilter: filter});
    this.displayFilterOptions()
  };

  filterProjects = (filter) => {
    const projects = this.props.projects;
    let filteredProjects = [];

    if (!filter) {
      return projects;
    }

    projects.forEach((project) => {
      let isInFilter = false;
      const industry = project.industry;
      const specialisms = project.specialism;

      const slug = industry.slug;
      if (slug === filter) {
        isInFilter = true;
      }

      specialisms.length > 0 && specialisms.forEach((specialism) => {
        const slug = specialism.slug;

        if (slug === filter) {
          isInFilter = true;
        }
      });

      if (isInFilter) {
        filteredProjects.push(project);
      }
    });

    return filteredProjects;
  };

  render() {
    const {intro, contentBlockTitle, contentBlockContent, specialisms, industries} = this.props;
    const {filterOptionsDisplayed, filterButtonHovered, activeFilter} = this.state;
    const {displayFilterOptions, hoverOverFilter, filterProjects, changeFilter} = this;
    let cursor;
    if (typeof window !== "undefined") {
        cursor = document.getElementById('custom-cursor');
    }

    const industriesData = [];
    let sizeIndustries = 1;
    for (let i = 0; i < industries.length; i += sizeIndustries) {
      if (i > 0) sizeIndustries = 2;
      industriesData.push(industries.slice(i, i + sizeIndustries));
    }

    const specialismsData = [];
    let sizeSpecialisms = 1;
    for (let i = 0; i < specialisms.length; i += sizeSpecialisms) {
      if (i > 0) sizeSpecialisms = 2;
      specialismsData.push(specialisms.slice(i, i + sizeSpecialisms));
    }
    const activeProjects = filterProjects(activeFilter);

    return (
      <ProjectsContainer>
        <RowStyled>
          <Container>
            <Fade duration={750} delay={100}>
              <Row>
                <ColStyled md={4} desktop>
                  <FilterButton onClick={() => displayFilterOptions()}
                                onMouseEnter={hoverOverFilter}
                                onMouseLeave={hoverOverFilter}
                                className="cursor-dot-black">
                    <ButtonText hover={filterButtonHovered}
                                open={filterOptionsDisplayed}>
                      filter projects
                    </ButtonText>
                    <Plus src={PlusDark} hover={filterButtonHovered} open={filterOptionsDisplayed} />
                  </FilterButton>
                </ColStyled>
                <ColStyled md={8}>
                  <ProjectsDescription>{intro}</ProjectsDescription>
                </ColStyled>
                <ColStyled mobile>
                  <FilterButton onClick={() => displayFilterOptions()}>
                    <ButtonText hover={filterButtonHovered}
                                open={filterOptionsDisplayed}>
                      filter projects
                    </ButtonText>
                    <Plus src={PlusDark} hover={filterButtonHovered} open={filterOptionsDisplayed}/>
                  </FilterButton>
                </ColStyled>
              </Row>
            </Fade>
          </Container>
        </RowStyled>
        <StyledContainer>
          <FilterContainer open={filterOptionsDisplayed} className="cursor-white">
            {filterOptionsDisplayed &&
            <FilterContent open={filterOptionsDisplayed}>
              <RowStyled filter={"true"}>
                <ColStyled xs={6} sm={6} md={6} filter={"true"}>
                  <Category>Specialism</Category>
                  <RowStyled filter={"true"}>
                    {specialismsData.map((specialism, index) =>
                      <ColStyled filter={"true"} md={6} key={index}>
                        {index === 0 &&
                        <SubCategory onClick={() => changeFilter(null)} selectedCategory={activeFilter === null} onMouseEnter={() => cursor?.classList?.add("dot-white")} onMouseLeave={() => cursor?.classList?.remove("dot-white")}>All
                          Specialisms</SubCategory>
                        }
                        {specialism.map((specialismData, index) =>
                          <SubCategory key={index} onClick={() => changeFilter(specialismData.node.slug)}
                                       selectedCategory={activeFilter === specialismData.node.slug}
                                       onMouseEnter={() => cursor?.classList?.add("dot-white")}
                                       onMouseLeave={() => cursor?.classList?.remove("dot-white")}>
                            {specialismData.node.name}
                          </SubCategory>
                        )}
                      </ColStyled>
                    )}
                  </RowStyled>
                </ColStyled>
                <ColStyled xs={6} sm={6} md={6} filter={"true"}>
                  <Category>Industry</Category>
                  <RowStyled filter={"true"}>
                    {industriesData.map((industry, index) =>
                      <ColStyled filter={"true"} md={6} key={index}>
                        {index === 0 &&
                        <SubCategory onClick={() => changeFilter(null)} selectedCategory={activeFilter === null} onMouseEnter={() => cursor?.classList?.add("dot-white")} onMouseLeave={() => cursor?.classList?.remove("dot-white")}>All
                          Industries</SubCategory>}
                        {industry.map((industryData, index) =>
                          <SubCategory key={index}
                                       onClick={() => changeFilter(industryData.node.slug)}
                                       selectedCategory={activeFilter === industryData.node.slug}
                                       onMouseEnter={() => cursor?.classList?.add("dot-white")}
                                       onMouseLeave={() => cursor?.classList?.remove("dot-white")}>{industryData.node.name}</SubCategory>
                        )}
                      </ColStyled>
                    )}
                  </RowStyled>
                </ColStyled>
              </RowStyled>
            </FilterContent>}
          </FilterContainer>
          <Fade duration={750} delay={100}>
            <ProjectsList projects={activeProjects}/>
          </Fade>
        </StyledContainer>
        <Container>
          <RowStyled bottom>
            <ColStyled lg={5} lgOffset={1} md={6}>
              <Fade duration={750}>
                <BigTitle>{contentBlockTitle}</BigTitle>
              </Fade>
            </ColStyled>
            <ColStyled lg={5} md={6}>
              <Fade duration={650} delay={100}>
                <DescriptionBottom>{contentBlockContent}</DescriptionBottom>
              </Fade>
            </ColStyled>
          </RowStyled>
        </Container>
      </ProjectsContainer>
    )
  }
}

export default Projects;
