import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import ProjectCard from "./ProjectCard";
import theme from "../../styles/theme";
import { Fade } from "react-reveal";

const ProjectsListContainer = styled(Container)`
    @media(min-width: ${theme.breakpoints.medium}){
      margin: -5px;
      width: calc(100% + 10px);
    }
`;

const ProjectsList = (props) => {

  const { projects } = props;
  const size = 2;
  let imagesRows = [];

  for (let i = 0; i < projects.length; i += size) {
    imagesRows.push(projects.slice(i, i + size));
  }

  return (
      <ProjectsListContainer>
        {imagesRows && imagesRows.map((imagesRow, index) => {
          if (index === 0 && imagesRow.length > 1) return (
              <React.Fragment key={index}>
                <Fade duration={750}>
                  <Row>
                    <Col md={8}>
                      <ProjectCard imagesRow={imagesRow[0]} index={index}/>
                    </Col>
                    <Col md={4}>
                      <ProjectCard imagesRow={imagesRow[1]} index={index}/>
                    </Col>
                  </Row>
                </Fade>
              </React.Fragment>
          );
          if (index % 2 === 1 && imagesRow.length > 1) return (
              <React.Fragment key={index}>
                <Fade duration={750}>
                  <Row>
                    <Col md={4}>
                      <ProjectCard imagesRow={imagesRow[0]} index={index}/>
                    </Col>
                    <Col md={8}>
                      <ProjectCard imagesRow={imagesRow[1]} index={index}/>
                    </Col>
                  </Row>
                </Fade>
              </React.Fragment>
          );
          if (index % 2 === 0 && index !== 0 && imagesRow.length > 1) return (
              <React.Fragment key={index}>
                <Fade duration={750}>
                  <Row>
                    <Col md={8}>
                      <ProjectCard imagesRow={imagesRow[0]} index={index}/>
                    </Col>
                    <Col md={4}>
                      <ProjectCard imagesRow={imagesRow[1]} index={index}/>
                    </Col>
                  </Row>
                </Fade>
              </React.Fragment>
        );
          if (imagesRow.length === 1) return (
              <React.Fragment key={index}>
                <Fade duration={750}>
                  <Row>
                    <Col md={12}>
                      <ProjectCard imagesRow={imagesRow[0]} index={index}/>
                    </Col>
                  </Row>
                </Fade>
              </React.Fragment>
          )
        })}
    </ProjectsListContainer>
  )
};

export default ProjectsList;
